<template>
<div>
<nav-bar></nav-bar>
  <div class="container-fluid page-body-wrapper">
  <side-bar></side-bar>
  <!-- partial -->
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="page-header">
        <h4 class="page-title" v-if="$route.params.id == null ||
            $route.params.id == ''">Add New Subscription Plan</h4>
        <h4 class="page-title" v-if="$route.params.id">Edit Subscription Plan</h4>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Subscription Plan Details</h4>
              <form class="form-sample">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Plan Name</label>
                        <input type="text" class="form-control" v-model="$v.typeform.plan_name.$model"
                            :class="{ 'is-invalid':  $v.typeform.plan_name.$error  }"
                            placeholder="Plan Name"/>
                        <div v-if="$v.typeform.plan_name.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.plan_name.required">Please enter plan name</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Plan Price</label>
                        <input type="number" class="form-control" v-model="$v.typeform.plan_price.$model"
                            :class="{ 'is-invalid':  $v.typeform.plan_price.$error  }"
                            placeholder="Plan Price"/>
                        <div v-if="$v.typeform.plan_price.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.plan_price.required">Please enter plan price</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Plan Discounted Price</label>
                        <input type="number" class="form-control" v-model="$v.typeform.plan_discounted_price.$model"
                            :class="{ 'is-invalid':  $v.typeform.plan_discounted_price.$error  }"
                            placeholder="Plan Discounted Price"/>
                        <div v-if="$v.typeform.plan_discounted_price.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.plan_discounted_price.required">Please enter plan discounted price</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Plan Validity(Days)</label>
                        <div class="input-group">
                            <input type="number" class="form-control" v-model="$v.typeform.plan_validity.$model"
                                :class="{ 'is-invalid':  $v.typeform.plan_validity.$error  }"
                                placeholder="Plan Validity(Days)"/>
                            <div class="input-group-append">
                                <span class="input-group-text">Days</span>
                              </div>
                        </div>
                        <div v-if="$v.typeform.plan_validity.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.plan_validity.required">Please enter plan validity</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Support In Time(Days)</label>
                        <div class="input-group">
                            <input type="number" class="form-control" v-model="$v.typeform.support_in_time.$model"
                                :class="{ 'is-invalid':  $v.typeform.support_in_time.$error  }"
                                placeholder="Support In Time(Days)"/>
                            <div class="input-group-append">
                                <span class="input-group-text">Days</span>
                              </div>
                        </div>
                        <div v-if="$v.typeform.support_in_time.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.support_in_time.required">Please enter support in time</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Plan Description</label>
                        <textarea class="form-control" v-model="$v.typeform.plan_description.$model"
                            :class="{ 'is-invalid':  $v.typeform.plan_description.$error  }"
                            placeholder="Plan Description"/>
                        <div v-if="$v.typeform.plan_description.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.plan_description.required">Please enter plan description</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Background</label>
                        <multiselect v-model="$v.typeform.selected_background.$model" :options="background"
                                     :multiple="false" :close-on-select="true" :clear-on-select="false"
                                     :preserve-search="true" placeholder="Background"
                                     :preselect-first="false" :showLabels="false"></multiselect>
                        <div v-if="$v.typeform.selected_background.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.selected_background.required">Please select background</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Auto Renewal</label>
                        <multiselect v-model="$v.typeform.filter_auto_renewal.$model"
                                    label= "name"
                                    :options="auto_renewal_list"
                                     :multiple="false" :close-on-select="true" :clear-on-select="false"
                                     :preserve-search="true" placeholder="Auto Renewal"
                                     :preselect-first="false" :showLabels="false"></multiselect>
                        <div v-if="$v.typeform.filter_auto_renewal.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.filter_auto_renewal.required">Please select auto renewal</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Status</label>
                        <multiselect v-model="$v.typeform.status.$model" :options="status"
                                     :multiple="false" :close-on-select="true" :clear-on-select="false"
                                     :preserve-search="true" placeholder="Status"
                                     :preselect-first="true" :showLabels="false"></multiselect>
                        <div v-if="$v.typeform.status.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.status.required">Please select status</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-3">
                    <h4 class="col-md-9 card-title">Features</h4>
                    <button type="button" @click="openSubscriptionFeatureModel({}, -1)" class="col-md-3 btn btn-gradient-primary">Add Feature</button>
                </div>

                <div class="table-responsive mt-3">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Feature Name</th>
                        <th>Feature Desc</th>
                        <th>Max Limit</th>
                        <th>Max Limit Period(Days)</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(obj,i) in features" :key="i">
                        <td>{{i + 1}}</td>
                        <td>{{getFeatureName(obj.feature_name)}}</td>
                        <td>{{obj.feature_desc}}</td>
                        <td>{{obj.max_limit}}</td>
                        <td>{{obj.max_limit_period}}</td>
                        <td><label v-bind:class="{'badge badge-success': obj.status === 'Active',
                                           'badge badge-danger': obj.status === 'InActive' ||
                                           obj.status === 'Inactive'}"
                                           @click="updateSubscriptionFeatureStatus(obj.id, obj.status, i)">{{obj.status}}</label></td>
                        <td>
                            <button type="button"
                                @click="openSubscriptionFeatureModel(obj, i)"
                                class="btn btn-outline-secondary btn-rounded btn-icon">
                                    <i class="mdi mdi-eye"></i>
                            </button>
                            &nbsp&nbsp
                            <button type="button"
                                @click="deleteFeature(i)"
                                class="btn btn-outline-secondary btn-rounded btn-icon">
                                    <i class="mdi mdi-delete"></i>
                            </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

               <div class="col-md-12">
                <b-spinner v-if="is_btn_spinner" label=""></b-spinner>
                <div v-if="!is_btn_spinner">
                    <button v-if="this.$route.params.id" type="button" @click="updateSubscriptionPlan()" class="btn btn-gradient-primary me-2">Update Plan</button>
                    <button v-else type="button" @click="addSubscriptionPlan()" class="btn btn-gradient-primary me-2">Add New Plan</button>
                </div>
               </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</div>
    <SubscriptionPlanFeatureModel ref="subscriptionFeatureDetailsComponent"/>

</div>
</template>
<script>
import NavBar from "@/components/NavBarMaster.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBarMaster.vue";
import { required } from "vuelidate/lib/validators";
import { mapActions} from "vuex";
import Multiselect from 'vue-multiselect';
import string from "../../../constant/strings.js"
import SubscriptionPlanFeatureModel from "../models/subscription_feature_details"

export default {
    name:'AddCITY',
    title: string.PAGE_TITLE_SUBSCRIPTION_PLAN_DETAILS,
    components: {
      NavBar,
      Footer,
      SideBar,
      Multiselect,
      SubscriptionPlanFeatureModel,
    },
    data() {
    return {
      status :['Active','Inactive'],
      background :['Green','Blue', 'Red'],
      auto_renewal_list:[{"id":"1", "name":"Yes"}, {"id":"0", "name":"No"}],
      is_listing_model:false,
      edit_index:0,
      features:[],
      typeform:{
        plan_name:'',
        plan_description:'',
        plan_price:'',
        plan_discounted_price:'',
        plan_validity:'',
        support_in_time:'',
        status:'',
        filter_auto_renewal: null,
        selected_background: null,
      },
      is_btn_spinner:false,
      feature_list: [
          {"id":"1", "name":"Dashboard"},
          {"id":"2", "name":"OPD"},
          {"id":"3", "name":"Appointment"},
          {"id":"4", "name":"Patients"},
          {"id":"5", "name":"UserManagement"},
          {"id":"6", "name":"Medicine"},
          {"id":"7", "name":"City"},
          {"id":"8", "name":"Reports"},
          {"id":"9", "name":"Settings"},
          {"id":"10", "name":"Changelog"},
          {"id":"11", "name":"Department"},
          {"id":"12", "name":"Room/Ward"},
          {"id":"13", "name":"IPD"},
          {"id":"14", "name":"Billing"},
      ],
      }
    },
    validations: {
    typeform: {
      plan_name:{required},
      plan_description:{required},
      plan_price:{required},
      plan_discounted_price:{required},
      plan_validity:{required},
      support_in_time:{required},
      status:{required},
      filter_auto_renewal:{required},
      selected_background:{required},
    },
  },
  mounted(){
    if(this.$route.params.id){
      this.getSubscriptionPlanDetails(this.$route.params.id)
    }
  },
  methods:{    
    ...mapActions("hospital",["addCityData","getSubscriptionPlanDetailsAPI",
                        "editCityDetailsData", "updateSubscriptionPlanFeatureStatusAPI",
                        "addSubscriptionPlanListAPI", "updateSubscriptionPlanAPI"
                        ]),

    addSubscriptionPlan(){
      this.$v.$touch();
      if (!this.$v.typeform.$invalid) {
          this.is_btn_spinner = true;
          var bodyFormData = new FormData();
          bodyFormData.append('plan_name', this.typeform.plan_name);
          bodyFormData.append('plan_description', this.typeform.plan_description);
          bodyFormData.append('plan_price', this.typeform.plan_price);
          bodyFormData.append('plan_discounted_price', this.typeform.plan_discounted_price);
          bodyFormData.append('plan_validity', this.typeform.plan_validity);
          bodyFormData.append('is_auto_renewal', this.typeform.filter_auto_renewal.id);
          bodyFormData.append('support_in_time', this.typeform.support_in_time);
          bodyFormData.append('background', this.typeform.selected_background);
          bodyFormData.append('status', this.typeform.status);
          bodyFormData.append('features_data', JSON.stringify(this.features));
          this.addSubscriptionPlanListAPI(bodyFormData).then((response) => {
            this.is_btn_spinner = false;
            if (response.response_code == 200) {
              this.$toasted.success(response.message, {duration: 2000,});
               this.$router.push({ name: 'master-admin-subscription-plans' });
            }else{
              this.$toasted.error(response.message, {duration: 2000,});
            }
          });
      }
    },
    updateSubscriptionPlan(){
      this.$v.$touch();
      if (!this.$v.typeform.$invalid) {
          this.is_btn_spinner = true;
          var bodyFormData = new FormData();
          bodyFormData.append('plan_id', this.$route.params.id);
          bodyFormData.append('plan_name', this.typeform.plan_name);
          bodyFormData.append('plan_description', this.typeform.plan_description);
          bodyFormData.append('plan_price', this.typeform.plan_price);
          bodyFormData.append('plan_discounted_price', this.typeform.plan_discounted_price);
          bodyFormData.append('plan_validity', this.typeform.plan_validity);
          bodyFormData.append('is_auto_renewal', this.typeform.filter_auto_renewal.id);
          bodyFormData.append('support_in_time', this.typeform.support_in_time);
          bodyFormData.append('background', this.typeform.selected_background);
          bodyFormData.append('status', this.typeform.status);
          bodyFormData.append('features_data', JSON.stringify(this.features));
          this.updateSubscriptionPlanAPI(bodyFormData).then((response) => {
            this.is_btn_spinner = false;
            if (response.response_code == 200) {
              this.$toasted.success(response.message, {duration: 2000,});
               this.$router.push({ name: 'master-admin-subscription-plans' });
            }else{
              this.$toasted.error(response.message, {duration: 2000,});
            }
          });
      }
    },

    getSubscriptionPlanDetails(id) {
      var bodyFormData = new FormData();      
      bodyFormData.append('plan_id', id);
      this.getSubscriptionPlanDetailsAPI(bodyFormData).then((response) => {
        if (response.response_code == 200) {          
            this.typeform = {
                plan_name: response.data.plan_name,
                plan_description: response.data.plan_description,
                plan_price: response.data.plan_price,
                plan_discounted_price: response.data.plan_discounted_price,
                plan_validity: response.data.plan_validity,
                filter_auto_renewal: this.getAutoRenewal(response.data.is_auto_renewal),
                support_in_time: response.data.support_in_time,
                selected_background: response.data.background,
                status: response.data.status,
            }
            this.features = response.data.features
        }else{
          this.$router.push({ name: 'cities' });
        }
      });
    },
    updateSubscriptionFeatureStatus(id, obj_status, index) {
      if(!id) {
        return
      }
      var bodyFormData = new FormData();
      bodyFormData.append('feature_id', id);
      if(obj_status == "Active") {
          bodyFormData.append('status', "Inactive");
        } else {
          bodyFormData.append('status', "Active");
        }
      this.updateSubscriptionPlanFeatureStatusAPI(bodyFormData).then((response) => {
        if (response.response_code == 200) {
            if (response.response_code == 200) {
                this.features.splice(index, 1, response.data);
            }else{
                this.errorMessage = response.message;
            }
        }else{
          this.$router.push({ name: 'cities' });
        }
      });
    },

    openSubscriptionFeatureModel(subscription_plan, index) {
        this.$refs.subscriptionFeatureDetailsComponent.clearData();
        this.$refs.subscriptionFeatureDetailsComponent.receiveData(subscription_plan);
        this.$refs.subscriptionFeatureDetailsComponent.show();
        this.edit_index = index
        if(this.is_listing_model == false){
            this.is_listing_model = true
            var myParent = this
            this.$refs.subscriptionFeatureDetailsComponent.$on("feature_details", function (feature_details) {
                if(myParent.edit_index >= 0) {
                    myParent.features.splice(myParent.edit_index, 1, feature_details);
                } else {
                    myParent.features.push(feature_details)
                }
            });
        }
    },

    deleteFeature(index) {
        this.features.splice(index, 1);
    },

    getAutoRenewal(value) {
        if(value) {
            return {"id":"1", "name":"Yes"}
        } else {
            return {"id":"0", "name":"No"}
        }
    },

    getFeatureName(value) {
        return this.feature_list[parseInt(value) -1].name
    },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
